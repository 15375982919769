import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, FormLabel, Grid, Stack, TextField } from '@mui/material'
import { updateUser } from 'api/users'
import FormInput from 'components/form-elements/FormInput'
import FormSelect from 'components/form-elements/FormSelect'
import NotificationSys from 'components/NotificationSystem'
import { EMAIL_IS_INVALID, FIELD_IS_REQUIRED } from 'const'
import { CohortsMultiSelect } from 'features/Cohorts'
import React, { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { AccessCodeDropdownItem, Pathway, TableResponse, UserModel, UserUpdateRequest } from 'types'
import { boolean, number, object, string, TypeOf } from 'zod'
import FormCheckbox from 'components/form-elements/FormCheckbox'
import { routes } from 'routes/routes'
import Breadcrumbs from 'components/Breadcumbs'

const formSchema = object({
  firstname: string().min(1, FIELD_IS_REQUIRED),
  lastname: string().min(1, FIELD_IS_REQUIRED),
  email: string().email(EMAIL_IS_INVALID),
  cohorts: object({
    label: string(),
    value: number(),
  }).array(),
  accessCodeId: number(),
  shouldLeaveOrganization: boolean(),
})

type FormType = TypeOf<typeof formSchema>

export function UserOrganizationDetailsForm({
  user,
  pathways,
  accessCodes,
  onAfterUpdate,
}: {
  user: UserModel
  pathways: TableResponse<Pathway>
  accessCodes: AccessCodeDropdownItem[]
  onAfterUpdate: () => void
}) {
  const defaultValues = {
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    cohorts: user.cohorts.map((cohort) => ({ label: cohort.name, value: cohort.id })),
    accessCodeId: user.accessCodeId,
    shouldLeaveOrganization: !!user.leftOrganizationAt,
  }

  const methods = useForm<FormType>({
    mode: 'all',
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const saveUserMutation = useMutation(updateUser)

  const getRequestData = useCallback(() => {
    const values = methods.getValues()

    const data: UserUpdateRequest = {
      id: user.id,
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      cohortIds: values.cohorts.map((item) => item.value),
      accessCodeId: values.accessCodeId,
      shouldLeaveOrganization: values.shouldLeaveOrganization,
    }

    return data
  }, [methods, user.id])

  const handleUpdate = useCallback(async () => {
    const request = getRequestData()
    await saveUserMutation.mutateAsync(request)
    NotificationSys.showSuccess('User updated')
    onAfterUpdate()
  }, [getRequestData, onAfterUpdate, saveUserMutation])

  const pathwayName = useMemo(() => {
    const pathway = pathways.data.rows.find(
      (item) => item.trainingPathwayTypeId === user.trainingPathwayTypeId,
    )
    return pathway ? pathway.trainingPathwayTypeName : ''
  }, [pathways, user.trainingPathwayTypeId])

  const accessCodeOptions = useMemo(() => {
    return accessCodes.map(({ id, code, specificState, specificUnitName }) => ({
      value: id,
      label: `${specificState}/${specificUnitName}/${code}`,
    }))
  }, [accessCodes])

  const path = useMemo(() => {
    return [
      { href: routes.orgUsers, text: 'Users' },
      {
        text: `${user.firstname} ${user.lastname}`,
      },
    ]
  }, [user.firstname, user.lastname])

  return (
    <>
      <Breadcrumbs path={path} />

      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={methods.handleSubmit(handleUpdate)}
          noValidate
          width="100%"
          mt={4}
        >
          <Grid container spacing={4}>
            <Grid item sm={12} md={12} lg={6} xl={6}>
              <Stack spacing={2}>
                <FormInput label="First Name" name="firstname" required fullWidth />
                <FormInput label="Last Name" name="lastname" required fullWidth />
                <FormInput label="Email" name="email" fullWidth disabled />

                <CohortsMultiSelect defaultValue={defaultValues.cohorts} />
              </Stack>

              <Stack spacing={2} mt={2}>
                {pathwayName && (
                  <Stack spacing={0.5}>
                    <FormLabel>Pathway Type</FormLabel>
                    <TextField size="small" value={pathwayName} disabled />
                  </Stack>
                )}
                <FormSelect
                  label="State/Unit"
                  name="accessCodeId"
                  required={!user.leftOrganizationAt}
                  options={accessCodeOptions}
                  disabled={!!user.leftOrganizationAt}
                />
                <FormCheckbox
                  name="shouldLeaveOrganization"
                  label="Left the Organization"
                  confirmText="Please confirm that the trainee has left the organization."
                  disabled={!!user.leftOrganizationAt}
                />
              </Stack>
            </Grid>

            <Grid item sm={12} md={12} lg={12} xl={12}>
              <LoadingButton
                disabled={saveUserMutation.isLoading}
                loading={saveUserMutation.isLoading}
                variant="contained"
                type="submit"
                sx={{ width: '120px' }}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </>
  )
}
